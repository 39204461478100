import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { systemModule } from '@/store/modules/system';
var Disclaimer = (function (_super) {
    __extends(Disclaimer, _super);
    function Disclaimer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Disclaimer.prototype.mounted = function () {
        systemModule.getDisclaimer();
    };
    Object.defineProperty(Disclaimer.prototype, "disclaimer", {
        get: function () {
            return systemModule.disclaimer;
        },
        enumerable: false,
        configurable: true
    });
    Disclaimer = __decorate([
        Component
    ], Disclaimer);
    return Disclaimer;
}(Vue));
export default Disclaimer;
